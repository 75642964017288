import { Chart, registerables } from "chart.js";
import ChartPluginStacked100 from "chartjs-plugin-stacked100";
import ChartDataLabels from "chartjs-plugin-datalabels";
import { useEffect, useRef, useState } from "react";
import { Bar } from "react-chartjs-2";
import { useDispatch, useSelector } from "react-redux";
import { fetchDaysFactors } from "../../store/slices/moodFactorsChartData";
import Translator from "../../resources/localization/translator";
import { MOCK_MOOD_FACTORS_DATASET, MOOD_COLORS } from "../../util/constants";

const MoodFactorsChart = ({ dateRange }) => {
  const { chartDate, checkupsTotal } = useSelector(
    (state) => state.moodChartData
  );
  const { daysFactors, daysFactorsLoadingStatus } = useSelector(
    (state) => state.moodFactorsChartData
  );
  const { themeParams, apiToken, language } = useSelector(
    (state) => state.userParams
  );
  const chartContainerRef = useRef(null);

  const dispatch = useDispatch();
  Chart.register(...registerables, ChartPluginStacked100, ChartDataLabels);
  useEffect(() => {
    dispatch(
      fetchDaysFactors({
        chartDate: chartDate,
        dateRange: dateRange,
        apiToken: apiToken,
        lang: language,
      })
    );
  }, [chartDate, dateRange, apiToken]);

  function sortAndFilterFactors(data, n) {
    if (daysFactorsLoadingStatus === "idle" && Array.isArray(data)) {
      return data.map((item) => {
        const sortedStats = [...item.factorB_stats].sort(
          (a, b) => b.count - a.count
        );
        const filteredStats = sortedStats.slice(0, n);
        return {
          factorA_name: item.factorA_name,
          factorB_stats: filteredStats,
        };
      });
    }
  }

  const sortedAndFilteredData = sortAndFilterFactors(daysFactors, 5);

  const legendPaddingPlugin = {
    id: "legendPaddingPlugin",
    beforeInit: function (chart, options) {
      const fitValue = chart.legend.fit;
      chart.legend.fit = function fit() {
        fitValue.bind(chart.legend)();
        this.height = this.height;
      };
    },
  };

  function generateChartData(data, visibleCount) {
    if (data) {
      const factorNamesSet = new Set();
      const visibleData = data.slice(0, visibleCount); // Ограничиваем данные до visibleCount элементов

      visibleData.forEach((factor) => {
        factorNamesSet.add(factor.factorA_name);
      });

      const labels = Array.from(factorNamesSet);
      const chartData = {
        labels: labels,
        datasets: [],
      };

      const valuesSet = new Set();
      visibleData.forEach((factor) => {
        factor.factorB_stats.forEach((stat) => {
          valuesSet.add(stat.value);
        });
      });

      const values = Array.from(valuesSet);

      values.forEach((value) => {
        const valueData = [];
        labels.forEach((label) => {
          const factor = visibleData.find(
            (factor) => factor.factorA_name === label
          );
          const stat = factor.factorB_stats.find(
            (stat) => stat.value === value
          );
          const count = stat ? stat.count : null;
          valueData.push(count);
        });

        const backgroundColor = MOOD_COLORS[value]
          ? MOOD_COLORS[value]
          : `rgba(${getRandomNumber(0, 255)}, ${getRandomNumber(
              0,
              255
            )}, ${getRandomNumber(0, 255)}, 0.6)`;

        const dataset = {
          label: value,
          data: valueData,
          backgroundColor: backgroundColor,
          barThickness: 20,
          borderWidth: 0,
          inflateAmount: 0,
          borderRadius: {
            topLeft: 5,
            topRight: 5,
            bottomLeft: 5,
            bottomRight: 5,
          },
          borderSkipped: false,
          datalabels: {
            anchor: "center",
            align: "center",
            color: "#fff",
          },
        };
        chartData.datasets.push(dataset);
      });
      return chartData;
    }
  }

  // Вспомогательная функция для генерации случайного числа в диапазоне
  function getRandomNumber(min, max) {
    return Math.floor(Math.random() * (max - min + 1) + min);
  }

  const initialVisibleCount = 4;
  const [visibleCount, setVisibleCount] = useState(initialVisibleCount);
  const [showAll, setShowAll] = useState(false);
  const [dataLength, setDataLength] = useState(0);

  const chartData = showAll
    ? generateChartData(daysFactors, visibleCount)
    : generateChartData(sortedAndFilteredData, visibleCount);

  const data =
    Array.isArray(daysFactors) && daysFactors.length === 0
      ? MOCK_MOOD_FACTORS_DATASET
      : chartData;

  useEffect(() => {
    setDataLength(daysFactors.length);
  }, [data]);

  const toggleVisibility = () => {
    if (daysFactors.length === 0) return;
    setShowAll(!showAll);
    setVisibleCount(showAll ? initialVisibleCount : data.length);
  };

  const containerClassName = `container ${showAll ? "expand" : ""}`;

  const options = {
    maintainAspectRatio: false,
    indexAxis: "y",
    elements: {
      bar: {
        borderWidth: 2,
        barThickness: 30, // Фиксированная ширина полосок
        maxBarThickness: 30,
      },
    },
    responsive: true,
    plugins: {
      stacked100: { enable: true },
      datalabels: {
        font: {
          family: "manrope",
          size: 12,
          weight: "bold",
        },
        formatter: (_value, context) => {
          if (_value === 0) return "";
          const data = context.chart.data;
          const { datasetIndex, dataIndex } = context;
          return `${data.originalData[datasetIndex][dataIndex]}`;
        },
      },
      tooltip: {
        callbacks: {
          label: (context) => {
            if (!context) return "";
            const data = context.chart.data;
            const { datasetIndex, dataIndex } = context;
            return `${context.dataset.label}: ${data.originalData[datasetIndex][dataIndex]}`;
          },
        },
      },
      legend: {
        maxWidth: 30,
        labels: {
          usePointStyle: true,
          boxHeight: 9,
          pointStyleWidth: 13,
          font: {
            family: "manrope",
            size: 12,
            weight: "bold",
          },
        },
      },
      title: {
        display: false,
      },
    },
    scales: {
      y: {
        stacked: true,
        grid: {
          display: false,
          drawBorder: false, // не отображаем координатную сетку
        },
        ticks: {
          display: true,
          font: {
            color: themeParams.text_color,
            family: "Manrope",
            style: "normal",
            weight: 700,
            size: 14,
          },
          color: themeParams.text_color,
          crossAlign: "far",
        },
      },
      x: {
        max: 100,
        stacked: true,
        display: false,
        grid: {
          display: false,
          drawBorder: false, // не отображаем координатную сетку
        },
      },
    },
  };
  useEffect(() => {
    // Обновление высоты холста при изменении размеров контейнера
    const updateChartHeight = () => {
      if (chartContainerRef.current) {
        const barCount = data?.labels.length;
        const barHeight = 20;
        const legendRowCount = Math.ceil(data?.datasets.length); // Количество строк легенды
        const legendHeight = legendRowCount * 10; // Высота легенды в пикселях
        const chartHeight =
          barCount * barHeight +
          legendHeight +
          (data?.labels.length > 1 ? 0 : 30);

        chartContainerRef.current.style.height = `${chartHeight}px`;
      }
    };

    updateChartHeight();
    window.addEventListener("resize", updateChartHeight);

    return () => {
      window.removeEventListener("resize", updateChartHeight);
    };
  }, [data]);

  const renderChart = () => {
    if (daysFactorsLoadingStatus === "loading") {
      return (
        <section className={`mood-chart-skeleton_${dateRange}`}>
          <div className={`mood-chart-skeleton_${dateRange}-container`}>
            <div className={`mood-chart-skeleton_${dateRange}`}>
              <div
                className={`mood-chart-skeleton_${dateRange} skeleton`}
              ></div>
            </div>
          </div>
        </section>
      );
    }
    if (daysFactorsLoadingStatus === "idle")
      return (
        <div>
          <div
            ref={chartContainerRef}
            style={{ width: "100%" }}
            className={containerClassName}
          >
            <Bar
              data={data}
              options={options}
              plugins={[legendPaddingPlugin]}
            />
          </div>
          <button
            className={`show-more-btn${dataLength > 4 ? "" : "_hidden"}`}
            onClick={toggleVisibility}
          >
            {Translator.translate(
              showAll ? "HIDE_MORE" : "SHOW_MORE",
              language
            )}
          </button>
        </div>
      );
  };
  return (
    <div
      className={`chart-wrapper${
        checkupsTotal < 2 && daysFactorsLoadingStatus === "idle" ? "_empty" : ""
      }`}
    >
      {daysFactorsLoadingStatus === "idle" ? (
        <p className={`chart-text${checkupsTotal < 2 ? "_empty" : ""}`}>
          Чтобы увидеть статистику, необходимо пройти хотя бы 2 чекапа
        </p>
      ) : null}
      <div
        className={
          daysFactorsLoadingStatus === "idle"
            ? `mood-chart-container${checkupsTotal < 2 ? "_blur" : ""}`
            : null
        }
      >
        {renderChart()}
      </div>
    </div>
  );
};

export default MoodFactorsChart;
