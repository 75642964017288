// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.month {
  min-height: 100px;
  width: 100%;
  border-radius: 5px;
}
.month-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 515px;
  width: 361px;
}
.month-img {
  min-height: 390px;
  border-radius: 5px;
}
.month-title {
  height: 85px;
  margin: 20px 0;
  border-radius: 5px;
  width: 100%;
  margin-top: 30px;
}`, "",{"version":3,"sources":["webpack://./src/components/SingleMonthSkeleton/SingleMonthSkeleton.scss"],"names":[],"mappings":"AAAA;EACE,iBAAA;EACA,WAAA;EACA,kBAAA;AACF;AAAE;EACE,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,aAAA;EACA,YAAA;AAEJ;AAAE;EACE,iBAAA;EACA,kBAAA;AAEJ;AAAE;EACE,YAAA;EACA,cAAA;EACA,kBAAA;EACA,WAAA;EACA,gBAAA;AAEJ","sourcesContent":[".month {\n  min-height: 100px;\n  width: 100%;\n  border-radius: 5px;\n  &-container {\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    height: 515px;\n    width: 361px;\n  }\n  &-img {\n    min-height: 390px;\n    border-radius: 5px;\n  }\n  &-title {\n    height: 85px;\n    margin: 20px 0;\n    border-radius: 5px;\n    width: 100%;\n    margin-top: 30px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
