// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.skeleton {
  background-color: #a7a7a7;
  overflow: hidden;
  position: relative;
  opacity: 0.1;
}

.skeleton::before {
  content: "";
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background: linear-gradient(to right, transparent, transparent, var(--tg-theme-text-color, #000000), transparent, transparent);
  transform: translate(-100%);
  animation: loading 1.2s infinite;
}

@keyframes loading {
  100% {
    transform: translate(100%);
  }
}`, "",{"version":3,"sources":["webpack://./src/resources/styles/skeleton.scss"],"names":[],"mappings":"AACA;EACE,yBAAA;EACA,gBAAA;EACA,kBAAA;EACA,YAAA;AAAF;;AAEA;EACE,WAAA;EACA,YAAA;EACA,WAAA;EACA,kBAAA;EACA,MAAA;EACA,OAAA;EACA,8HAAA;EAQA,2BAAA;EACA,gCAAA;AANF;;AASA;EACE;IACE,0BAAA;EANF;AACF","sourcesContent":["@import \"./variables.scss\";\n.skeleton {\n  background-color: #a7a7a7;\n  overflow: hidden;\n  position: relative;\n  opacity: 0.1;\n}\n.skeleton::before {\n  content: \"\";\n  height: 100%;\n  width: 100%;\n  position: absolute;\n  top: 0;\n  left: 0;\n  background: linear-gradient(\n    to right,\n    transparent,\n    transparent,\n    $text,\n    transparent,\n    transparent\n  );\n  transform: translate(-100%);\n  animation: loading 1.2s infinite;\n}\n\n@keyframes loading {\n  100% {\n    transform: translate(100%);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
