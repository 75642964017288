import cn from "classnames";
import styles from "./button.module.scss";
import { FC, SVGAttributes } from "react";

interface Props extends React.HTMLAttributes<HTMLButtonElement> {
  title: string;
  size: "big" | "medium" | "small";
  variant: "filled" | "ghost";
  icon?: FC<SVGAttributes<SVGElement>>;
  className?: string;
  iconClassName?: string;
  disabled?: boolean;
}
const Button = ({
  title,
  size,
  variant,
  icon: Icon,
  className,
  iconClassName,
  disabled = false,
  ...args
}: Props) => {
  return (
    <button
      className={cn(
        styles.button,
        {
          [styles.disabled]: disabled,
          [styles.small]: size === "small",
          [styles.medium]: size === "medium",
          [styles.large]: size === "big",
          [styles.filled]: variant === "filled",
          [styles.ghost]: variant === "ghost",
        },
        className
      )}
      disabled={disabled}
      {...args}
    >
      <p className={styles.title}>{title}</p>
      {!!Icon && <Icon className={iconClassName} />}
    </button>
  );
};
export default Button;
