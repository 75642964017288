import { createSlice, createAsyncThunk, current } from "@reduxjs/toolkit";
import { API } from "../../../util/constants";
import getMonth from "date-fns/getMonth";
import getYear from "date-fns/getYear";
import {
  parseData,
  getFirstCheckupDate,
  getDatesArray,
  getPaginationMonthCount,
} from "../../../util/helpers";

const monthCount = 12;
const initialState = {
  // languageLoadingStatus: false,
  currentDate: new Date(),
  datesArray: getDatesArray(new Date(), monthCount, false),
  firstCheckupDate: new Date(1970, 1, 1),
  datesWithCheckup: {},
  datesWithCheckupLoadingStatus: "loading",
  monthCounterDate: new Date(),
  prevMonthLoading: false,
  newItemsLoading: false,
  backToTopVisible: false,
  target: null,
  scrollPosition: 0,
  queryToggle: true,
  resetDataToggle: false,
};

export const fetchDatesWithCheckup = createAsyncThunk(
  "datesWithCheckup/fetchDatesWithCheckup",
  async (
    {
      dataFromComponent,
      isFirstLoad = false,
      apiKey,
      shareToken,
      resetDataToggle,
    },
    { dispatch, rejectWithValue }
  ) => {
    const month = getMonth(dataFromComponent);
    const year = getYear(dataFromComponent);
    try {
      const requestParams = {
        month: month + 1,
        year,
        api_token: apiKey,
        get_first_checkup: true,
        till_today: true,
      };

      if (shareToken) {
        requestParams.share_token = shareToken;
      }

      if (resetDataToggle) {
        dispatch(setDatesWithCheckup({}));
      }
      dispatch(setResetDataToggle(false));

      const response =
        await API.getCheckupsForMonth.getMonthCheckupsGetCheckupsForMonthGet(
          requestParams
        );

      if (!isFirstLoad) {
        //dispatch(addMonth(dataFromComponent));
      }
      //dispatch(setLanguage(response.language));
      // dispatch(setLanguageLoadingStatus(true));
      return response.data;
    } catch (err) {
      let error = err;
      if (!error.response) throw err;

      return rejectWithValue(error.response.data);
    }
  }
);

const CalendarSlice = createSlice({
  name: "datesWithCheckup",
  initialState,
  reducers: {
    // setLanguageLoadingStatus: (state, { payload }) => {
    //   state.languageLoadingStatus = payload;
    // },
    setResetDataToggle: (state, { payload }) => {
      state.resetDataToggle = payload;
    },
    setScrollPosition: (state, { payload }) => {
      state.scrollPosition = payload;
    },
    setQueryToggle: (state, { payload }) => {
      state.queryToggle = payload;
    },
    toggleBackToTopVisible: (state, { payload }) => {
      state.backToTopVisible = payload;
    },
    setTarget: (state, { payload }) => {
      state.target = payload;
    },
    changeMonthCounterDate: (state, action) => {
      state.newItemsLoading = true;
      state.monthCounterDate = action.payload;
    },
    changeInitialCountOfMonths: (state, action) => {
      state.initialCountOfMonths = action.payload;
    },
    changePrevMonthLoading: (state, action) => {
      state.prevMonthLoading = action.payload;
    },
    addMonth: (state, action) => {
      state.datesArray = [
        ...state.datesArray,
        ...getDatesArray(
          action.payload,
          getPaginationMonthCount(
            state.monthCounterDate,
            state.firstCheckupDate
          )
        ),
      ];
    },
    setDatesWithCheckup: (state, action) => {
      state.datesWithCheckup = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchDatesWithCheckup.pending, (state) => {
        state.datesWithCheckupLoadingStatus = "loading";
      })
      .addCase(fetchDatesWithCheckup.fulfilled, (state, action) => {
        state.datesWithCheckupLoadingStatus = "idle";
        state.datesWithCheckup = parseData(
          action.payload,
          current(state.datesWithCheckup)
        );
        state.firstCheckupDate = getFirstCheckupDate(action.payload);
      })
      .addCase(fetchDatesWithCheckup.rejected, (state) => {
        state.datesWithCheckupLoadingStatus = "error";
      })
      .addDefaultCase(() => {});
  },
});

const { actions, reducer } = CalendarSlice;

export default reducer;
export const {
  setScrollPosition,
  toggleBackToTopVisible,
  setTarget,
  changeMonthCounter,
  changeYearCounter,
  changePrevMonthLoading,
  addMonth,
  changeMonthCounterDate,
  setQueryToggle,
  setLanguageLoadingStatus,
  setDatesWithCheckup,
  setResetDataToggle,
} = actions;
