// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.button_button__2\\+PU1 {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 6px;
  transition: all 0.3s ease-in-out;
}
.button_button__2\\+PU1:disabled {
  opacity: 0.6;
}
.button_button__2\\+PU1:disabled:hover {
  opacity: 0.6;
}

.button_big__6sOx4 {
  padding: 16px 0;
  border-radius: 44px;
}
.button_big__6sOx4 .button_title__v6FGF {
  font-size: 16px;
  font-weight: 700;
  line-height: 21.86px;
  text-align: left;
}

.button_medium__rGwuT {
  padding: 11px 0;
  border-radius: 44px;
}
.button_medium__rGwuT .button_title__v6FGF {
  font-size: 14px;
  font-weight: 600;
  line-height: 17.5px;
}

.button_filled__h33pB {
  background-color: var(--tg-theme-button-color, #7868e6);
}
.button_filled__h33pB .button_title__v6FGF {
  color: var(--tg-theme-button-text-color, #ffffff);
}

.button_ghost__PG\\+Pb {
  border: 1px solid var(--tg-theme-button-color, #7868e6);
}
.button_ghost__PG\\+Pb .button_title__v6FGF {
  color: var(--tg-theme-button-color, #7868e6);
}`, "",{"version":3,"sources":["webpack://./src/UI/Button/button.module.scss","webpack://./src/resources/styles/variables.scss"],"names":[],"mappings":"AAEA;EACE,WAAA;EACA,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,QAAA;EACA,gCAAA;AADF;AAGE;EACE,YAAA;AADJ;AAGI;EACE,YAAA;AADN;;AAMA;EACE,eAAA;EACA,mBAAA;AAHF;AAIE;EACE,eAAA;EACA,gBAAA;EACA,oBAAA;EACA,gBAAA;AAFJ;;AAMA;EACE,eAAA;EACA,mBAAA;AAHF;AAIE;EACE,eAAA;EACA,gBAAA;EACA,mBAAA;AAFJ;;AAMA;EACE,uDC9BO;AD2BT;AAIE;EACE,iDC/BU;AD6Bd;;AAMA;EACE,uDAAA;AAHF;AAIE;EACE,4CCvCK;ADqCT","sourcesContent":["@import \"../../resources/styles/variables.scss\";\n\n.button {\n  width: 100%;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  gap: 6px;\n  transition: all 0.3s ease-in-out;\n\n  &:disabled {\n    opacity: 0.6;\n\n    &:hover {\n      opacity: 0.6;\n    }\n  }\n}\n\n.big {\n  padding: 16px 0;\n  border-radius: 44px;\n  .title {\n    font-size: 16px;\n    font-weight: 700;\n    line-height: 21.86px;\n    text-align: left;\n  }\n}\n\n.medium {\n  padding: 11px 0;\n  border-radius: 44px;\n  .title {\n    font-size: 14px;\n    font-weight: 600;\n    line-height: 17.5px;\n  }\n}\n\n.filled {\n  background-color: $button;\n  .title {\n    color: $button-text;\n  }\n}\n\n.ghost {\n  border: 1px solid $button;\n  .title {\n    color: $button;\n  }\n}\n","@use \"sass:color\";\n$white: #ffffff;\n$light-gray: #f8f8f8;\n$light-blue: #eeeef8;\n$blue: #7868e6;\n$gray: rgba(60, 60, 67, 0.3);\n$black: #1d1938;\n$purple: rgba(120, 104, 230, 0.28);\n$bg: var(--tg-theme-bg-color, #f8f8f8);\n$bg-secondary: var(--tg-theme-secondary-bg-color, #edeef7);\n$text: var(--tg-theme-text-color, #000000);\n$button: var(--tg-theme-button-color, $blue);\n$button-text: var(--tg-theme-button-text-color, $white);\n$hint: var(--tg-theme-hint-color, #68b9e6);\n$link: var(--tg-theme-link-color, #7868e6);\n$separator: var(--tg-theme-section-separator-color, #f9f9f9);\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"button": `button_button__2+PU1`,
	"big": `button_big__6sOx4`,
	"title": `button_title__v6FGF`,
	"medium": `button_medium__rGwuT`,
	"filled": `button_filled__h33pB`,
	"ghost": `button_ghost__PG+Pb`
};
export default ___CSS_LOADER_EXPORT___;
