// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.app {
  background-color: var(--tg-theme-secondary-bg-color, #edeef7);
  box-sizing: border-box;
  width: 100%;
}`, "",{"version":3,"sources":["webpack://./src/components/App/App.scss","webpack://./src/resources/styles/variables.scss"],"names":[],"mappings":"AACA;EACE,6DCOa;EDNb,sBAAA;EACA,WAAA;AAAF","sourcesContent":["@import \"../../resources/styles/variables.scss\";\n.app {\n  background-color: $bg-secondary;\n  box-sizing: border-box;\n  width: 100%;\n  //position: fixed;\n  //width: 100%;\n  //position: fixed;\n}\n","@use \"sass:color\";\n$white: #ffffff;\n$light-gray: #f8f8f8;\n$light-blue: #eeeef8;\n$blue: #7868e6;\n$gray: rgba(60, 60, 67, 0.3);\n$black: #1d1938;\n$purple: rgba(120, 104, 230, 0.28);\n$bg: var(--tg-theme-bg-color, #f8f8f8);\n$bg-secondary: var(--tg-theme-secondary-bg-color, #edeef7);\n$text: var(--tg-theme-text-color, #000000);\n$button: var(--tg-theme-button-color, $blue);\n$button-text: var(--tg-theme-button-text-color, $white);\n$hint: var(--tg-theme-hint-color, #68b9e6);\n$link: var(--tg-theme-link-color, #7868e6);\n$separator: var(--tg-theme-section-separator-color, #f9f9f9);\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
