import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { API } from "../../../util/constants";
import getMonth from "date-fns/getMonth";
import getYear from "date-fns/getYear";

const initialState = {
  checkup: {},
  checkupId: "",
  checkupLoadingStatus: "loading",
};

export const fetchCheckup = createAsyncThunk(
  "checkupInfo/fetchCheckup",
  async (
    { dataFromComponent, isFirstLoad = false, apiToken, shareToken },
    { dispatch, rejectWithValue }
  ) => {
    // const day = dataFromComponent.getDate();
    // const month = getMonth(dataFromComponent);
    // const year = getYear(dataFromComponent);
    const requestParams = {
      day: dataFromComponent.getDate(),
      month: getMonth(dataFromComponent) + 1,
      year: getYear(dataFromComponent),
      api_token: apiToken,
    };
    if (shareToken) {
      requestParams.share_token = shareToken;
    }

    try {
      const response = await API.getDaySummary.getDaySummaryGetDaySummaryGet(
        requestParams
      );

      if (!isFirstLoad) {
        //dispatch(addMonth(dataFromComponent));
      }
      return response.data;
    } catch (err) {
      let error = err;
      if (!error.response) throw err;

      return rejectWithValue(error.response.data);
    }
  }
);
export const deleteCheckup = createAsyncThunk(
  "checkupInfo/deleteCheckup",
  async (
    { dataFromComponent, checkupId, apiToken },
    { dispatch, rejectWithValue }
  ) => {
    const requestParams = {
      api_token: apiToken,
    };

    try {
      const response = await API.checkups.deleteCheckupCheckupsCheckupIdDelete(
        checkupId,
        requestParams
      );

      // Вызываем санку fetchCheckup после успешного удаления
      await dispatch(
        fetchCheckup({
          dataFromComponent,
          apiToken,
        })
      );

      return response.data;
    } catch (err) {
      let error = err;
      if (!error.response) throw err;

      return rejectWithValue(error.response.data);
    }
  }
);

const CheckupSlice = createSlice({
  name: "checkupInfo",
  initialState,
  reducers: {
    createId: (state, action) => {
      state.checkupId = action.payload;
    },
    setCheckup: (state, action) => {
      state.checkup = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchCheckup.pending, (state) => {
        state.checkup = {};
        state.checkupLoadingStatus = "loading";
      })
      .addCase(fetchCheckup.fulfilled, (state, action) => {
        state.checkupLoadingStatus = "idle";
        state.checkup = action.payload;
      })
      .addCase(fetchCheckup.rejected, (state) => {
        state.checkupLoadingStatus = "error";
      })
      .addDefaultCase(() => {});
  },
});

const { actions, reducer } = CheckupSlice;

export default reducer;
export const { createId, setCheckup } = actions;
