import { FC } from "react";
import styles from "./GPTSuggestionTextarea.module.scss";
import kukuha from "../../resources/images/kukuha.png";
import { ReactComponent as CursorSVG } from "../../resources/images/cursor.svg";

interface Props {
  suggestion: string;
  loadingStatus: string;
}
const GPTSuggestionTextarea: FC<Props> = ({ suggestion, loadingStatus }) => {
  return (
    <div className={styles.container}>
      <h2 className={styles.title}>Рекомендации от Кукухи AI</h2>
      <div className={styles.content}>
        <div className={styles.header}>
          <img className={styles.img} src={kukuha} alt="Кукуха AI" />
          <p className={styles.name}>Кукуха AI</p>
        </div>
        <p className={styles.text}>
          {suggestion}
          {loadingStatus === "loading" && (
            <span>
              <CursorSVG />
            </span>
          )}
        </p>
      </div>
    </div>
  );
};
export default GPTSuggestionTextarea;
