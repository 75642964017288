import { MouseEventHandler, useEffect } from "react";
import YearlyReflectionTextarea from "../../components/YearlyReflectionTextarea";
import Button from "../../UI/Button";
import PageNavigation from "../../UI/PageNavigation";
import styles from "./resultsPage.module.scss";
import { useDispatch, useSelector } from "react-redux";
import {
  generateYearlyFeedback,
  getYearlyReflection,
} from "../../store/slices/yearlyReflection";
import { AppDispatch } from "../../store";
import GPTSuggestionTextarea from "../../components/GPTSuggestionTextarea";
import { ReactComponent as ShareSVG } from "./ShareIcon.svg";
import { ReactComponent as CircleSVG } from "../../resources/images/typingCircle.svg";
import { AxiosError } from "axios";
import { API, TELEGRAM } from "../../util/constants";
import { sendMetrics } from "../../util/metrics";
import { setStartPage } from "../../store/slices/userParams";

interface ClickHandler extends Function {
  cantClick?: boolean;
}

const YearlyReflectionPage = () => {
  const dispatch: AppDispatch = useDispatch();
  const { apiToken, viewedByAdmin, firstTimeOpen } = useSelector(
    (state: UserParamsSlice) => state.userParams
  );
  const { yearlyReflection, yearlyFeedbackStatus, yearlyFeedback } =
    useSelector((state: YearlyReflectionSlice) => state.yearlyReflection);

  useEffect(() => {
    if (firstTimeOpen) dispatch(setStartPage("yearlyReflections"));
  }, []);

  useEffect(() => {
    !yearlyReflection && dispatch(getYearlyReflection({ apiToken }));
  }, [apiToken]);

  // useEffect(() => {
  //   if (apiToken) {
  //     fetch(
  //       `${process.env.REACT_APP_API_BASE_URL}/delete_yearly_feedback?api_token=${apiToken}`,
  //       {
  //         method: "GET",
  //       }
  //     );
  //   }
  // }, [apiToken]);

  const openPurchaseSub = async () => {
    console.log("openPurchaseSub");
    try {
      const response = await API.yearlyReflection.purchaseSubscription({
        api_token: apiToken,
      });

      return response;
    } catch (err) {
      let error = err;
      if (!error.response) throw err;

      return error;
    }
  };

  const sendData = () => {
    TELEGRAM.HapticFeedback.impactOccurred("heavy");
    if (!viewedByAdmin) {
      sendMetrics("reachGoal", "click-yearly-popup-purchase-btn");
    }

    openPurchaseSub();

    setTimeout(() => {
      TELEGRAM.close();
    }, 0);
  };

  const clickHandler: MouseEventHandler<HTMLButtonElement> &
    ClickHandler = async () => {
    if (clickHandler.cantClick) return;
    clickHandler.cantClick = true;

    const resultAction = await dispatch(generateYearlyFeedback({ apiToken }));

    if (
      generateYearlyFeedback.rejected.match(resultAction) &&
      (resultAction.payload as AxiosError).status === 403
    ) {
      sendData();
    }

    setTimeout(() => {
      clickHandler.cantClick = false;
    }, 500);
  };

  const handleShareMessage = async () => {
    try {
      const preparedMessage = await API.yearlyReflection.prepareMessage({
        api_token: apiToken,
      });

      // Получаем msg_id из результата savePreparedInlineMessage
      const msg_id = preparedMessage.data.id;
      if (!msg_id) {
        console.error("Не удалось сохранить сообщение.");
      }

      // Используйте метод shareMessage с полученным msg_id
      TELEGRAM.shareMessage(msg_id, (success) => {
        if (success) {
          console.log("Сообщение успешно отправлено.");
        } else {
          console.log("Пользователь отменил отправку сообщения.");
        }
      });
    } catch (error) {
      console.error("Ошибка при создании или отправке сообщения:", error);
    }
  };

  function isYearlyReflectionComplete(reflection: YearlyReflection): boolean {
    console.log(
      Object.entries(reflection).every(([key, value]) => {
        if (key === "gpt_suggestion") return true; // Игнорируем поле gpt_suggestion
        return !!value; // Проверяем, что значение не null
      })
    );
    return Object.entries(reflection).every(([key, value]) => {
      if (key === "gpt_suggestion") return true; // Игнорируем поле gpt_suggestion
      return !!value; // Проверяем, что значение не null
    });
  }

  return (
    <>
      <div className={styles.container}>
        <h1 className={styles.title}>Итоги года, 2024</h1>
        <p className={styles.subtitle} style={{ marginTop: "8px" }}>
          Заполни анкету об уходящем годе и получи рекомендации от AI-психолога,
          Кукухи
        </p>
        {yearlyReflection && (
          <section className={styles.items}>
            <YearlyReflectionTextarea
              alias="Январь"
              name="january"
              placeholder="Как прошел месяц"
              value={yearlyReflection.january}
            />
            <YearlyReflectionTextarea
              alias="Февраль"
              name="february"
              placeholder="Как прошел месяц"
              value={yearlyReflection.february}
            />
            <YearlyReflectionTextarea
              alias="Март"
              name="march"
              placeholder="Как прошел месяц"
              value={yearlyReflection.march}
            />
            <YearlyReflectionTextarea
              alias="Апрель"
              name="april"
              placeholder="Как прошел месяц"
              value={yearlyReflection.april}
            />
            <YearlyReflectionTextarea
              alias="Май"
              name="may"
              placeholder="Как прошел месяц"
              value={yearlyReflection.may}
            />
            <YearlyReflectionTextarea
              alias="Июнь"
              name="june"
              placeholder="Как прошел месяц"
              value={yearlyReflection.june}
            />
            <YearlyReflectionTextarea
              alias="Июль"
              name="july"
              placeholder="Как прошел месяц"
              value={yearlyReflection.july}
            />
            <YearlyReflectionTextarea
              alias="Август"
              name="august"
              placeholder="Как прошел месяц"
              value={yearlyReflection.august}
            />
            <YearlyReflectionTextarea
              alias="Сентябрь"
              name="september"
              placeholder="Как прошел месяц"
              value={yearlyReflection.september}
            />
            <YearlyReflectionTextarea
              alias="Октябрь"
              name="october"
              placeholder="Как прошел месяц"
              value={yearlyReflection.october}
            />
            <YearlyReflectionTextarea
              alias="Ноябрь"
              name="november"
              placeholder="Как прошел месяц"
              value={yearlyReflection.november}
            />
            <YearlyReflectionTextarea
              alias="Декабрь"
              name="december"
              placeholder="Как прошел месяц"
              value={yearlyReflection.december}
            />
          </section>
        )}
        <p className={styles.subtitle} style={{ marginBottom: "8px" }}>
          Какие настройки текущей реальности хотелось бы сохранить?
        </p>
        {yearlyReflection && (
          <YearlyReflectionTextarea
            alias=""
            name="positive_note"
            placeholder="Введите текст"
            value={yearlyReflection.positive_note}
            alwaysShowTextarea
          />
        )}
        <p className={styles.subtitle} style={{ marginBottom: "8px" }}>
          Что оставить в прошлом году?
        </p>
        {yearlyReflection && (
          <YearlyReflectionTextarea
            alias=""
            name="negative_note"
            placeholder="Введите текст"
            value={yearlyReflection.negative_note}
            alwaysShowTextarea
          />
        )}
        {yearlyFeedback && (
          <div style={{ paddingBottom: "16px" }}>
            <GPTSuggestionTextarea
              suggestion={yearlyFeedback}
              loadingStatus={yearlyFeedbackStatus}
            />
          </div>
        )}
        <div style={{ marginTop: "8px" }}>
          {yearlyReflection && (
            <div className={styles.buttonsContainer}>
              {yearlyReflection && !yearlyFeedback && (
                <Button
                  size="medium"
                  variant="filled"
                  title="Получить рекомендации"
                  onClick={
                    yearlyFeedbackStatus !== "loading" ? clickHandler : () => {}
                  }
                  icon={yearlyFeedbackStatus === "loading" && CircleSVG}
                  iconClassName={styles.rotating}
                  disabled={Object.entries(yearlyReflection).some(
                    ([key, value]) => key !== "gpt_suggestion" && !value
                  )}
                />
              )}
              {yearlyFeedback && (
                <Button
                  size="medium"
                  variant="ghost"
                  title="Получить новую рекомендацию"
                  onClick={() => {
                    dispatch(
                      generateYearlyFeedback({
                        apiToken,
                      })
                    );
                  }}
                  disabled={yearlyFeedbackStatus === "loading"}
                />
              )}
              {
                <Button
                  size="medium"
                  variant="filled"
                  title="Поделиться"
                  icon={ShareSVG}
                  onClick={handleShareMessage}
                  disabled={!isYearlyReflectionComplete(yearlyReflection)}
                />
              }
            </div>
          )}
        </div>
        {!yearlyFeedback && <div style={{ height: "40vh" }} />}
      </div>
      <PageNavigation />
    </>
  );
};
export default YearlyReflectionPage;
