// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.checkup {
  height: 100vh;
}
.checkup-header {
  height: 20px;
}
.checkup__content {
  padding: 76px 20px 20px 20px;
}
.checkup__content__description {
  border-radius: 5px;
  height: 56px;
  line-height: 56px;
}
.checkup__content__section {
  display: flex;
  justify-content: center;
  gap: 11px;
  margin-top: 10px;
}
.checkup__content__note {
  margin-top: 10px;
}
.checkup__content__item {
  border-radius: 5px;
  width: 100%;
  height: 77px;
}`, "",{"version":3,"sources":["webpack://./src/components/CheckupSkeleton/CheckupSkeleton.scss"],"names":[],"mappings":"AACA;EACE,aAAA;AAAF;AACE;EACE,YAAA;AACJ;AACE;EACE,4BAAA;AACJ;AAAI;EACE,kBAAA;EACA,YAAA;EACA,iBAAA;AAEN;AAAI;EACE,aAAA;EACA,uBAAA;EACA,SAAA;EACA,gBAAA;AAEN;AAAI;EACE,gBAAA;AAEN;AAAI;EACE,kBAAA;EACA,WAAA;EACA,YAAA;AAEN","sourcesContent":["@import \"../../resources/styles/variables.scss\";\n.checkup {\n  height: 100vh;\n  &-header {\n    height: 20px;\n  }\n  &__content {\n    padding: 76px 20px 20px 20px;\n    &__description {\n      border-radius: 5px;\n      height: 56px;\n      line-height: 56px;\n    }\n    &__section {\n      display: flex;\n      justify-content: center;\n      gap: 11px;\n      margin-top: 10px;\n    }\n    &__note {\n      margin-top: 10px;\n    }\n    &__item {\n      border-radius: 5px;\n      width: 100%;\n      height: 77px;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
