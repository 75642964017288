import { ApiClient } from "../api/api-client";

import awfulEmotionPic from "../resources/images/awful.svg";
import badEmotionPic from "../resources/images/bad.svg";
import averageEmotionPic from "../resources/images/average.svg";
import goodEmotionPic from "../resources/images/good.svg";
import perfectEmotionPic from "../resources/images/perfect.svg";
import marina from "../resources/images/marina.png";

export const TELEGRAM = window.Telegram.WebApp;
export const MAIN_BUTTON = TELEGRAM.MainButton;
export const WEEK_DAY_NAMES = ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"];

export const DAYS_IN_WEEK = 7;

export const DAYS_IN_MONTH = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];

export const WEEK_DAYS_FROM_MONDAY = [6, 0, 1, 2, 3, 4, 5];

export const MONTHS = {
  January: 0,
  February: 1,
  March: 2,
  April: 3,
  May: 4,
  June: 5,
  July: 6,
  August: 7,
  September: 8,
  October: 9,
  Novermber: 10,
  December: 11,
};

export const API = new ApiClient({
  baseURL: process.env.REACT_APP_API_BASE_URL,
});

export const LOCALE = "ru";

export const EMOTION_PICS = {
  awful: awfulEmotionPic,
  bad: badEmotionPic,
  average: averageEmotionPic,
  good: goodEmotionPic,
  perfect: perfectEmotionPic,
};

////CHARTS

export const MOOD_CHART_COLORS = [
  "#B7D58B",
  "#CBD58B",
  "#FCE770",
  "#EC9696",
  "#F18686",
];

export const MOOD_CHART_COLORS_REVERSE = [
  "#F18686",
  "#EC9696",
  "#FCE770",
  "#CBD58B",
  "#B7D58B",
];

export const MOOD_COLORS = {
  Радость: "#FFEB3B",
  Счастье: "#FFC107",
  Уверенность: "#FF5722",
  "Подъем сил": "#4CAF50",
  Интерес: "#03A9F4",
  Любовь: "#E91E63",
  Вдохновение: "#9C27B0",
  Триумф: "#673AB7",
  Спокойствие: "#009688",
  Нежность: "#FF9FF3",
  Грусть: "#607D8B",
  Тревога: "#FF9800",
  Злость: "#F44336",
  Одиночество: "#795548",
  Обида: "#9E9E9E",
  Страх: "#9C27B0",
  Апатия: "#D27E00",
  Опасения: "#3D73FF",
  Ревность: "#8D48FE",
  Вина: "#FF3DEC",
  Sadness: "#607D8B",
  Fear: "#9C27B0",
  Anxiety: "#FF9800",
  Apathy: "#D27E00",
  Anger: "#F44336",
  Concerns: "#3D73FF",
  Loneliness: "#795548",
  Jealousy: "#8D48FE",
  Resentment: "#9E9E9E",
  Guilt: "Вина",
  Joy: "#FFEB3B",
  Love: "#E91E63",
  Happiness: "#FFC107",
  Inspiration: "#9C27B0",
  Confidence: "#FF5722",
  Triumph: "#673AB7",
  Strong: "#4CAF50",
  Calmness: "#009688",
  Interest: "#03A9F4",
  Tenderness: "#FF9FF3",
};

export const MOCK_MOOD_FACTORS_DATASET = {
  labels: ["Работа", "Финансы", "Учеба", "Здоровье"],
  datasets: [
    {
      label: "Радость",
      data: [10, 6, 0, 2],
      backgroundColor: "#B7D58B",
      barThickness: 20,
      borderWidth: 0,
      barShowStroke: false,
      barStrokeWidth: 0,
      barRadius: 0,
      skipNull: true,
      inflateAmount: 0,
      borderRadius: {
        topLeft: 5,
        topRight: 5,
        bottomLeft: 5,
        bottomRight: 5,
      },
      datalabels: {
        anchor: "center",
        align: "center",
        color: "#fff",
      },
    },
    {
      label: "Уверенность",
      data: [10, 6, 4, 2],
      backgroundColor: "#F18686",
      barThickness: 20,
      borderWidth: 0,
      barShowStroke: false,
      barStrokeWidth: 0,
      barRadius: 0,
      skipNull: true,
      inflateAmount: 0,
      borderRadius: {
        topLeft: 5,
        topRight: 5,
        bottomLeft: 5,
        bottomRight: 5,
      },
      datalabels: {
        anchor: "center",
        align: "center",
        color: "#fff",
      },
    },
    {
      label: "Усталость",
      data: [2, 0, 4, 2],
      backgroundColor: "#B8B5FF",
      barThickness: 20,
      borderWidth: 0,
      barShowStroke: false,
      barStrokeWidth: 0,
      barRadius: 0,
      skipNull: true,
      inflateAmount: 0,
      borderRadius: {
        topLeft: 5,
        topRight: 5,
        bottomLeft: 5,
        bottomRight: 5,
      },
      datalabels: {
        anchor: "center",
        align: "center",
        color: "#fff",
      },
    },
    {
      label: "Уверенность",
      data: [8, 0, 4, 10],
      backgroundColor: "#68B9E6",
      barThickness: 20,
      borderWidth: 0,
      barShowStroke: false,
      barStrokeWidth: 0,
      barRadius: 0,
      skipNull: true,
      inflateAmount: 0,
      borderRadius: {
        topLeft: 5,
        topRight: 5,
        bottomLeft: 5,
        bottomRight: 5,
      },
      datalabels: {
        anchor: "center",
        align: "center",
        color: "#fff",
      },
    },
  ],
};

export const psychologists = [
  {
    id: 1,
    firstName: "Мария",
    lastName: "Соловьева",
    priceHalfHour: 333,
    priceHour: 4444,
    profilePicture: marina,
    yearsOfExperience: 3,
    therapyMethods: [
      "Транзактный анализ",
      "КПТ",
      "Кризисная психилогия",
      "Коучинг",
    ],
  },
  {
    id: 2,
    firstName: "Мария",
    lastName: "Соловьева",
    priceHalfHour: 333,
    priceHour: 4444,
    profilePicture: marina,
    yearsOfExperience: 3,
    therapyMethods: [
      "Транзактный анализ",
      "КПТ",
      "Кризисная психилогия",
      "Коучинг",
    ],
  },
  {
    id: 3,
    firstName: "Мария",
    lastName: "Соловьева",
    priceHalfHour: 333,
    priceHour: 4444,
    profilePicture: marina,
    yearsOfExperience: 3,
    therapyMethods: [
      "Транзактный анализ",
      "КПТ",
      "Кризисная психилогия",
      "Коучинг",
    ],
  },
];
