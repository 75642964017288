// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.error-page {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.error-page__title {
  margin: 80px 0 36px 0;
  font-weight: 600;
  font-size: 24px;
  line-height: 112.5%;
  text-align: center;
  letter-spacing: 0.431px;
  color: var(--tg-theme-text-color, #000000);
}
.error-page__footer {
  width: 229px;
  display: flex;
  flex-direction: column;
  margin-top: 72px;
  gap: 10px;
}
.error-page__button_reload {
  color: var(--tg-theme-button-text-color, #f3f3f3);
  padding: 14px 0;
  border-radius: 51px;
  background-color: var(--tg-theme-button-color, #7868e6);
}
.error-page__button_reload:active {
  opacity: 0.7;
}
.error-page__button_support {
  color: var(--tg-theme-text-color, #000);
  padding: 14px 0;
  border-radius: 51px;
  background-color: var(--tg-theme-bg-color, #edeef7);
  display: flex;
  justify-content: center;
}
.error-page__button_support:active {
  opacity: 0.7;
}

p {
  font-family: "Manrope";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 120%;
}`, "",{"version":3,"sources":["webpack://./src/UI/ErrorMessage/ErrorMessage.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,sBAAA;EACA,mBAAA;AACF;AAAE;EACE,qBAAA;EACA,gBAAA;EACA,eAAA;EACA,mBAAA;EACA,kBAAA;EACA,uBAAA;EACA,0CAAA;AAEJ;AAAE;EACE,YAAA;EACA,aAAA;EACA,sBAAA;EACA,gBAAA;EACA,SAAA;AAEJ;AACI;EACE,iDAAA;EACA,eAAA;EACA,mBAAA;EACA,uDAAA;AACN;AAAM;EACE,YAAA;AAER;AACI;EACE,uCAAA;EACA,eAAA;EACA,mBAAA;EACA,mDAAA;EACA,aAAA;EACA,uBAAA;AACN;AAAM;EACE,YAAA;AAER;;AAGA;EACE,sBAAA;EACA,kBAAA;EACA,gBAAA;EACA,eAAA;EACA,iBAAA;AAAF","sourcesContent":[".error-page {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  &__title {\n    margin: 80px 0 36px 0;\n    font-weight: 600;\n    font-size: 24px;\n    line-height: 112.5%;\n    text-align: center;\n    letter-spacing: 0.431px;\n    color: var(--tg-theme-text-color, #000000);\n  }\n  &__footer {\n    width: 229px;\n    display: flex;\n    flex-direction: column;\n    margin-top: 72px;\n    gap: 10px;\n  }\n  &__button {\n    &_reload {\n      color: var(--tg-theme-button-text-color, #f3f3f3);\n      padding: 14px 0;\n      border-radius: 51px;\n      background-color: var(--tg-theme-button-color, #7868e6);\n      &:active {\n        opacity: 0.7;\n      }\n    }\n    &_support {\n      color: var(--tg-theme-text-color, #000);\n      padding: 14px 0;\n      border-radius: 51px;\n      background-color: var(--tg-theme-bg-color, #edeef7);\n      display: flex;\n      justify-content: center;\n      &:active {\n        opacity: 0.7;\n      }\n    }\n  }\n}\np {\n  font-family: \"Manrope\";\n  font-style: normal;\n  font-weight: 400;\n  font-size: 14px;\n  line-height: 120%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
