// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.calendar-page {
  overscroll-behavior: none;
  overflow-y: auto;
  overflow-x: hidden;
  position: relative;
  top: -50px;
  padding-top: calc(var(--tg-content-safe-area-inset-top, 0px) + var(--tg-safe-area-inset-top, 0px) + 80px);
}`, "",{"version":3,"sources":["webpack://./src/pages/CalendarPage/CalendarPage.scss"],"names":[],"mappings":"AAAA;EAEE,yBAAA;EACA,gBAAA;EACA,kBAAA;EACA,kBAAA;EACA,UAAA;EACA,yGAAA;AACF","sourcesContent":[".calendar-page {\n  -ms-scroll-chaining: none;\n  overscroll-behavior: none;\n  overflow-y: auto;\n  overflow-x: hidden;\n  position: relative;\n  top: -50px;\n  padding-top: calc(\n    var(--tg-content-safe-area-inset-top, 0px) +\n      var(--tg-safe-area-inset-top, 0px) + 80px\n  );\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
