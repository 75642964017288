import * as React from "react";
import { Link } from "react-router-dom";
import "./UserPanel.scss";
import { ReactComponent as SharingButton } from "../../resources/images/shareBtn.svg";
import { ReactComponent as ArrowBtn } from "../../resources/images/upArrow.svg";
import { useDispatch, useSelector } from "react-redux";
import {
  setActiveProperties,
  setActiveUserName,
  setActiveUserPicId,
  setActiveUserTgUsername,
  setIsOwnerDiary,
} from "../../store/slices/sharingData";
import { TELEGRAM } from "../../util/constants";
import { setShareToken } from "../../store/slices/userParams";
import { setResetDataToggle } from "../../store/slices/calendarData";
import ViewableUser from "../ViewableUser";
import Translator from "../../resources/localization/translator";

interface ComponentProps {
  // title: string;
}

const UserPanel: React.FC<ComponentProps> = (
  {
    /* Деструктуризация пропсов */
  }
) => {
  const dispatch = useDispatch();
  const { apiToken, language, viewedByAdmin, shareToken } = useSelector(
    (state: UserParamsSlice) => state.userParams
  );
  const {
    viewableDiaries,
    diaryOwnerPicId,
    diaryOwnerName,
    diaryOwnerTgUsername,
    activeUserPicId,
    activeUserName,
    activeUserTgUsername,
    isOwnerDiary,
    viewableDiariesLoadingStatus,
  } = useSelector((state: SharingDataSlice) => state.sharingData);

  const [isPopupOpen, setPopupOpen] = React.useState(false);
  const [isImageLoaded, setIsImageLoaded] = React.useState(true);

  const handleImageLoad = () => {
    setIsImageLoaded(true);
  };

  const handleImageError = () => {
    setIsImageLoaded(false);
  };

  React.useEffect(() => {
    setIsImageLoaded(true);
  }, [activeUserPicId]);

  const handleDiariesClick = () => {
    if (
      isOwnerDiary &&
      viewableDiaries &&
      "diaries" in viewableDiaries &&
      viewableDiaries.diaries.length > 1
    ) {
      TELEGRAM.HapticFeedback.impactOccurred("light");
      setPopupOpen(!isPopupOpen);
    }
  };

  const setActiveUser = (
    shareToken: string,
    picId: string,
    name: string,
    tgUsername: string,
    properties: string
  ) => {
    TELEGRAM.HapticFeedback.impactOccurred("light");
    dispatch(setShareToken(shareToken));
    dispatch(setActiveUserPicId(picId));
    dispatch(setActiveUserName(name));
    dispatch(setActiveUserTgUsername(tgUsername));
    dispatch(setActiveProperties(properties));
    dispatch(setResetDataToggle(true));
    setPopupOpen(false);
    dispatch(setIsOwnerDiary(false));
  };

  const getDiaryOwner = () => {
    TELEGRAM.HapticFeedback.impactOccurred("heavy");
    dispatch(setShareToken(null));
    dispatch(setActiveUserPicId(diaryOwnerPicId));
    dispatch(setActiveUserName(diaryOwnerName));
    dispatch(setActiveUserTgUsername(diaryOwnerTgUsername));
    dispatch(setActiveProperties(""));
    dispatch(setResetDataToggle(true));
    dispatch(setIsOwnerDiary(true));
  };

  const renderComponent = (diaries: SharingViewersArray) => {
    return diaries.map((diary, index: number) => {
      if (index !== 0) {
        return (
          <div
            className={`viewable-diaries-popup__profile ${
              index !== diaries.length - 1 ? "border-bottom" : ""
            }`}
            key={index}
            onClick={() =>
              setActiveUser(
                diary.token,
                diary.profile_pic_file_id,
                diary.name,
                diary.tg_username,
                diary.access
              )
            }
          >
            <ViewableUser diary={diary} />
            <button className="viewable-diaries-popup__info-btn">
              <ArrowBtn />
            </button>
          </div>
        );
      }
    });
  };

  return (
    <>
      <div className="user-panel">
        <div className="user-panel-container">
          <div className="user-panel__profile" onClick={handleDiariesClick}>
            <div className="user-panel__profile-avatar">
              {isImageLoaded && activeUserPicId && (
                <img
                  src={`https://api.monday4.me/get_profile_pic?api_token=${apiToken}&profile_pic_file_id=${
                    isOwnerDiary ? diaryOwnerPicId : activeUserPicId
                  }`}
                  onLoad={handleImageLoad}
                  onError={handleImageError}
                />
              )}

              <p className="user-panel__profile-avatar__literal">
                {activeUserName ? activeUserName[0] : ""}
              </p>
            </div>
            <div className="user-panel__info">
              <div className="user-panel__info-name-block">
                <div className="user-panel__info-name">
                  {viewableDiariesLoadingStatus === "idle"
                    ? isOwnerDiary
                      ? diaryOwnerName
                      : activeUserName
                    : Translator.translate("USER_NAME", language)}
                </div>
                {viewableDiaries &&
                "diaries" in viewableDiaries &&
                viewableDiaries.diaries.length > 1 &&
                isOwnerDiary ? (
                  <button className="user-panel__info-btn">
                    <ArrowBtn />
                  </button>
                ) : null}
              </div>
              <div className="user-panel__info-telegram">
                {viewableDiariesLoadingStatus === "idle"
                  ? activeUserTgUsername
                    ? `@${
                        isOwnerDiary
                          ? diaryOwnerTgUsername
                          : activeUserTgUsername
                      }`
                    : ""
                  : Translator.translate("USER_NICKNAME", language)}
              </div>
            </div>
          </div>
          <div className="user-panel__btn-block">
            {shareToken && !isOwnerDiary ? (
              <button
                className="user-panel__exit-btn"
                onClick={() => getDiaryOwner()}
              >
                {Translator.translate("LEAVE", language)}
              </button>
            ) : (
              <Link
                to={`/sharing/?token=${apiToken}&language=${language}&viewed_by_admin=${viewedByAdmin}`}
              >
                <button
                  className="user-panel__sharing-btn"
                  onClick={() =>
                    TELEGRAM.HapticFeedback.impactOccurred("heavy")
                  }
                >
                  <SharingButton />
                </button>
              </Link>
            )}
          </div>
        </div>
      </div>
      {isPopupOpen && (
        <div className="viewable-diaries-popup">
          <p className="viewable-diaries-popup__title">
            {Translator.translate("AVAILABLE_DIARIES", language)}
          </p>
          {viewableDiaries &&
            "diaries" in viewableDiaries &&
            renderComponent(viewableDiaries.diaries)}
        </div>
      )}
    </>
  );
};

export default UserPanel;
