// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.scroll-button {
  position: fixed;
  padding: 12px 12px;
  right: 5%;
  font-size: 16px;
  z-index: 10;
  -webkit-user-select: none;
          user-select: none;
  cursor: pointer;
  background-color: var(--tg-theme-button-color, #7868e6);
  border-radius: 50%;
  box-shadow: 0px 4px 10px rgba(29, 25, 56, 0.2);
  transition: all 0.3s cubic-bezier(0.33, 1, 0.68, 1);
}
.scroll-button_dark {
  position: fixed;
  padding: 12px 12px;
  right: 5%;
  font-size: 16px;
  z-index: 10;
  -webkit-user-select: none;
          user-select: none;
  cursor: pointer;
  background-color: var(--tg-theme-button-color, #7868e6);
  border-radius: 50%;
  box-shadow: 0px 4px 10px rgba(239, 239, 244, 0.3);
  transition: all 0.3s cubic-bezier(0.33, 1, 0.68, 1);
}
.scroll-button__content {
  height: 26px;
  width: 26px;
  display: flex;
  align-items: center;
}
.scroll-button__content svg {
  width: 100%;
}`, "",{"version":3,"sources":["webpack://./src/UI/ScrollButton/ScrollButton.scss"],"names":[],"mappings":"AAEA;EACE,eAAA;EACA,kBAAA;EACA,SAAA;EACA,eAAA;EACA,WAAA;EACA,yBAAA;UAAA,iBAAA;EACA,eAAA;EACA,uDAAA;EACA,kBAAA;EACA,8CAAA;EACA,mDAAA;AADF;AAEE;EACE,eAAA;EACA,kBAAA;EACA,SAAA;EACA,eAAA;EACA,WAAA;EACA,yBAAA;UAAA,iBAAA;EACA,eAAA;EACA,uDAAA;EACA,kBAAA;EACA,iDAAA;EACA,mDAAA;AAAJ;AAEE;EACE,YAAA;EACA,WAAA;EACA,aAAA;EACA,mBAAA;AAAJ;AACI;EACE,WAAA;AACN","sourcesContent":["@import \"../../resources/styles/variables.scss\";\n\n.scroll-button {\n  position: fixed;\n  padding: 12px 12px;\n  right: 5%;\n  font-size: 16px;\n  z-index: 10;\n  user-select: none;\n  cursor: pointer;\n  background-color: var(--tg-theme-button-color, $blue);\n  border-radius: 50%;\n  box-shadow: 0px 4px 10px rgba(29, 25, 56, 0.2);\n  transition: all 0.3s cubic-bezier(0.33, 1, 0.68, 1);\n  &_dark {\n    position: fixed;\n    padding: 12px 12px;\n    right: 5%;\n    font-size: 16px;\n    z-index: 10;\n    user-select: none;\n    cursor: pointer;\n    background-color: var(--tg-theme-button-color, $blue);\n    border-radius: 50%;\n    box-shadow: 0px 4px 10px rgba(239, 239, 244, 0.3);\n    transition: all 0.3s cubic-bezier(0.33, 1, 0.68, 1);\n  }\n  &__content {\n    height: 26px;\n    width: 26px;\n    display: flex;\n    align-items: center;\n    svg {\n      width: 100%;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
